.Dialog {
  background: $Dialog-background;
  border: $Dialog-border;
  border-radius: $Dialog-borderRadius;
  box-shadow: $Dialog-boxShadow;
  color: $Dialog-color;
  display: flex;
  flex-direction: column;
  height: auto;
  left: 50%;
  max-height: calc(100% - $Content-gutter * 2);
  max-width: $Content-maxWidth;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - $Content-gutter * 2);
  z-index: $Dialog-zIndex;

  @include for-desktop() {
    flex-direction: row;
  }

  @media (prefers-reduced-motion: no-preference) {
    &[data-state="closed"] {
      animation: DialogClosed 125ms ease-in;

      @keyframes DialogClosed {
        from {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }

        to {
          opacity: 0;
          transform: translate(-50%, -48%) scale(0.96);
        }
      }
    }

    &[data-state="open"] {
      animation: DialogOpen 125ms ease-out;

      @keyframes DialogOpen {
        from {
          opacity: 0;
          transform: translate(-50%, -48%) scale(0.96);
        }

        to {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  &:focus {
    outline: none;
  }

  > div[aria-hidden="true"] {
    position: relative;

    @include for-desktop() {
      flex-grow: 1;
      max-width: 13.5rem;
      width: 100%;
    }

    > img,
    > picture > img {
      height: 10rem;
      object-fit: cover;
      width: 100%;

      @include for-desktop() {
        height: 100%;
        position: absolute;
        width: 13.5rem;
      }
    }
  }

  > div:last-child {
    display: contents;

    @include for-desktop() {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &-body {
    flex-grow: 1;
    overflow: auto;
    padding: 0 $Content-gutter;

    &:nth-last-child(2) {
      padding-bottom: $Content-gutter;
    }

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }
  }

  &-close {
    font-size: 1.5rem;
    position: absolute;
    right: $Content-gutter;
    top: $Content-gutter;
    z-index: 1;
  }

  &-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: calc($Content-gutter * -0.5) 0 0 calc($Content-gutter * -0.5);
    padding: $Content-gutter;

    > a,
    > button,
    > div,
    > span {
      margin: calc($Content-gutter * 0.5) 0 0 calc($Content-gutter * 0.5);
    }
  }

  &-header {
    padding: $Content-gutter;

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }
  }

  &-title {
    font-family: $Dialog-title-fontFamily;
    font-weight: $Dialog-title-fontWeight;
    line-height: $Dialog-title-lineHeight;
    margin: 0;
    width: 100%;
    @include font-size($Dialog-title-fontSize);

    > svg {
      background: $Icon-background;
      border-radius: 1.25rem;
      color: $Icon-color;
      height: 2.5rem;
      margin-right: calc($Content-gutter * 0.5);
      padding: 0.5rem;
      width: 2.5rem;
    }

    &[data-icon="true"] {
      border-bottom: $Separator;
      padding-bottom: $Content-gutter;
    }
  }

  &[data-image="true"] & {
    &-header {
      background: $Dialog-background;
      margin: calc($Content-gutter * -2) 0 0 $Content-gutter;
      z-index: 1;

      @include for-desktop() {
        margin: $Content-gutter 0 0 calc($Content-gutter * -2);
      }
    }
  }

  $sizes: (
    alert: (
      height: null,
      maxWidth: 60rem,
      minHeight: 30rem,
    ),
    full: (
      height: 100%,
      maxWidth: null,
      minHeight: null,
    ),
    lg: (
      height: null,
      maxWidth: 60rem,
      minHeight: null,
    ),
    sm: (
      height: null,
      maxWidth: 30rem,
      minHeight: null,
    ),
    xl: (
      height: null,
      maxWidth: null,
      minHeight: null,
    ),
  );
  @each $size, $rules in $sizes {
    &[data-size="#{$size}"] {
      height: map-get($rules, height);
      max-width: map-get($rules, maxWidth);

      @include for-desktop() {
        min-height: map-get($rules, minHeight);
      }
    }
  }
}
