@import "../../styles/abstracts";

.AuthenticationPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  > a,
  > div {
    margin-bottom: 1rem;
  }

  > a {
    align-items: center;
    color: $Brand-color;
    display: inline-flex;
    font-weight: bold;

    &::after {
      margin-left: 0.25rem;
    }

    &::before {
      margin-right: 0.25rem;
    }
  }

  > h1 {
    color: $Brand-color;
  }
}
