.Nav {
  background: $Nav-background;
  border: $Nav-border;
  border-radius: $Nav-borderRadius;
  box-shadow: $Nav-boxShadow;
  color: $Nav-color;
  width: 100%;

  > div {
    ul {
      display: flex;
      list-style: none;
      margin: 0 0 0 calc($Content-gutter * -1);
      padding: 0;

      > li {
        margin: 0 0 0 $Content-gutter;

        > a,
        > button {
          align-items: center;
          background: $Nav-link-background;
          border: $Nav-link-border;
          border-radius: $Nav-link-borderRadius;
          box-shadow: $Nav-link-boxShadow;
          color: $Nav-link-color;
          display: flex;
          font-family: $Nav-link-fontFamily;
          font-size: $Nav-link-fontSize;
          font-weight: $Nav-link-fontWeight;
          line-height: $Nav-link-lineHeight;
          padding: $Nav-link-padding;
          text-decoration: none;
          width: 100%;

          &[aria-current],
          &:focus,
          &:hover {
            background: $Nav-link-onHover-background;
            border: $Nav-link-onHover-border;
            color: $Nav-link-onHover-color;
          }

          > span {
            margin-left: calc($Content-gutter * 0.5);
          }

          > svg {
            margin-right: calc($Content-gutter * 0.5);
          }
        }

        > div {
          background: $Nav-dropdown-background;
          border: $Nav-dropdown-border;
          border-radius: $Nav-dropdown-borderRadius;
          box-shadow: $Nav-dropdown-boxShadow;
          color: $Nav-dropdown-color;
          padding: $Content-gutter;
          position: relative;

          @include for-desktop() {
            position: absolute;
          }

          > div {
            > div {
              > ul {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  &[data-orientation="vertical"] {
    > div {
      > ul {
        flex-direction: column;

        > li {
          &:not(:last-child) {
            border-bottom: $Separator;
          }
        }
      }
    }
  }
}
