.PasswordField {
  > div:first-of-type {
    align-items: center;
    display: flex;
    position: relative;

    > button {
      position: absolute;
      right: $Content-gutter;

      &::before {
        @include pseudo-icon(
          escape-svg(
            url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 40 40'><path d='M24.9659 20.337L27.2378 18.0651C27.4031 18.6835 27.5 19.3301 27.5 20.0001C27.5 24.1357 24.1356 27.5001 20 27.5001C19.33 27.5001 18.6834 27.4032 18.065 27.2379L20.3369 24.966C22.8209 24.7966 24.7966 22.821 24.9659 20.337ZM39.8425 19.3929C39.6888 19.1163 37.5081 15.2954 33.2456 12.0573L31.4266 13.8763C34.5347 16.1616 36.5041 18.827 37.2794 20.002C35.7978 22.2579 29.9859 30.0001 20 30.0001C18.5025 30.0001 17.11 29.8085 15.7997 29.5029L13.7456 31.557C15.6341 32.136 17.7097 32.5001 20 32.5001C33.0853 32.5001 39.5728 21.0926 39.8425 20.6073C40.0525 20.2295 40.0525 19.7707 39.8425 19.3929V19.3929ZM34.6338 7.13383L7.13375 34.6338C6.88969 34.8779 6.56969 35.0001 6.25 35.0001C5.93031 35.0001 5.61031 34.8779 5.36625 34.6338C4.87781 34.1454 4.87781 33.3545 5.36625 32.8663L8.865 29.3676C3.20875 25.9529 0.334687 20.9263 0.1575 20.6073C-0.0525 20.2295 -0.0525 19.7704 0.1575 19.3926C0.427187 18.9076 6.91469 7.50008 20 7.50008C23.3834 7.50008 26.3141 8.27195 28.8194 9.4132L32.8662 5.36633C33.3547 4.87789 34.1456 4.87789 34.6338 5.36633C35.1219 5.85477 35.1222 6.6457 34.6338 7.13383V7.13383ZM10.7291 27.5035L13.8937 24.3388C13.0197 23.1126 12.5 21.6173 12.5 20.0001C12.5 15.8645 15.8644 12.5001 20 12.5001C21.6172 12.5001 23.1125 13.0198 24.3388 13.8935L26.8978 11.3345C24.8759 10.5248 22.5784 10.0001 20 10.0001C10.0141 10.0001 4.20219 17.7423 2.72094 19.9982C3.66156 21.4238 6.36531 25.0326 10.7291 27.5035ZM15.7084 22.5238L22.5237 15.7085C21.7803 15.2695 20.9244 15.0001 20 15.0001C17.2431 15.0001 15 17.2432 15 20.0001C15 20.9245 15.2694 21.7804 15.7084 22.5238V22.5238Z' fill='currentColor' /></svg>")
          ),
          20px,
          20px
        );
      }
    }

    > input {
      padding-right: calc($Content-gutter * 1.5 + 20px);
    }
  }

  &[data-hidden="true"] {
    > div:first-of-type {
      > button {
        &::before {
          @include pseudo-icon(
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 40 40'><path d='M20 32.5C6.91469 32.5 0.427188 21.0925 0.1575 20.6072C-0.0525 20.2294 -0.0525 19.7703 0.1575 19.3925C0.427188 18.9075 6.91469 7.5 20 7.5C33.0853 7.5 39.5728 18.9075 39.8425 19.3928C40.0525 19.7706 40.0525 20.2297 39.8425 20.6075C39.5728 21.0925 33.0853 32.5 20 32.5ZM2.72094 19.9981C4.20781 22.2516 10.0456 30 20 30C29.9859 30 35.7978 22.2578 37.2791 20.0019C35.7922 17.7484 29.9544 10 20 10C10.0141 10 4.20219 17.7422 2.72094 19.9981ZM20 27.5C15.8644 27.5 12.5 24.1356 12.5 20C12.5 15.8644 15.8644 12.5 20 12.5C24.1356 12.5 27.5 15.8644 27.5 20C27.5 24.1356 24.1356 27.5 20 27.5ZM20 15C17.2431 15 15 17.2431 15 20C15 22.7569 17.2431 25 20 25C22.7569 25 25 22.7569 25 20C25 17.2431 22.7569 15 20 15Z' fill='currentColor' /></svg>")
            ),
            20px,
            20px
          );
        }
      }
    }
  }
}
