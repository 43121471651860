.Alert {
  align-items: center;
  background: $background--default;
  border: 1px solid $borderColor--default;
  color: $color--default;
  display: flex;
  font-weight: bold;
  padding: calc($Content-gutter * 0.5) $Content-gutter;

  &::before {
    margin-right: calc($Content-gutter * 0.5);
  }

  $statuses: (
    error: (
      background: $background--error,
      borderColor: $borderColor--error,
      color: $color--error,
      icon:
        url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 16 16'><path d='M15.3009 15.13H0.692188C0.448709 15.13 0.222622 14.9909 0.0834919 14.7822C-0.0382473 14.5735 -0.0382473 14.3126 0.0834919 14.0865L7.38784 1.21697C7.50958 0.99088 7.73567 0.869141 7.99654 0.869141C8.25741 0.869141 8.48349 1.00827 8.60523 1.21697L15.9096 14.0865C16.0313 14.2952 16.0313 14.5735 15.9096 14.7822C15.7705 14.9909 15.5444 15.13 15.3009 15.13ZM1.89219 13.7387H14.1183L7.99654 2.97349L1.89219 13.7387ZM8.69219 9.58218V6.3474C8.69219 5.96479 8.37915 5.65175 7.99654 5.65175C7.61393 5.65175 7.30088 5.96479 7.30088 6.3474V9.58218C7.30088 9.96479 7.61393 10.2778 7.99654 10.2778C8.37915 10.2778 8.69219 9.96479 8.69219 9.58218ZM8.60523 12.4691C8.76175 12.3126 8.8661 12.0865 8.8661 11.8604C8.8661 11.6344 8.77915 11.4083 8.60523 11.2517C8.44871 11.0952 8.22262 10.9909 7.99654 10.9909C7.77045 10.9909 7.54436 11.0778 7.38784 11.2517C7.23132 11.4083 7.12697 11.6344 7.12697 11.8604C7.12697 12.0865 7.21393 12.3126 7.38784 12.4691C7.54436 12.6257 7.77045 12.73 7.99654 12.73C8.22262 12.73 8.44871 12.6257 8.60523 12.4691Z' fill='currentColor' /></svg>"),
    ),
    info: (
      background: $background--info,
      borderColor: $borderColor--info,
      color: $color--info,
      icon:
        url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 16 16'><circle cx='8' cy='8' fill='currentColor' r='5' /></svg>"),
    ),
    success: (
      background: $background--success,
      borderColor: $borderColor--success,
      color: $color--success,
      icon:
        url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 22 16'><path d='M3.10281 7.15538C2.59312 6.64623 1.76719 6.64667 1.25804 7.15636C0.748892 7.66604 0.749328 8.49197 1.25902 9.00113L7.98406 15.7191L21.0022 2.7126C21.5119 2.20341 21.5122 1.37748 21.003 0.86783C20.4939 0.358182 19.6679 0.357811 19.1583 0.867002L7.98391 12.0313L3.10281 7.15538Z' fill='currentColor' /></svg>"),
    ),
    warning: (
      background: $background--warning,
      borderColor: $borderColor--warning,
      color: $color--warning,
      icon:
        url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 16 16'><path d='M15.3009 15.13H0.692188C0.448709 15.13 0.222622 14.9909 0.0834919 14.7822C-0.0382473 14.5735 -0.0382473 14.3126 0.0834919 14.0865L7.38784 1.21697C7.50958 0.99088 7.73567 0.869141 7.99654 0.869141C8.25741 0.869141 8.48349 1.00827 8.60523 1.21697L15.9096 14.0865C16.0313 14.2952 16.0313 14.5735 15.9096 14.7822C15.7705 14.9909 15.5444 15.13 15.3009 15.13ZM1.89219 13.7387H14.1183L7.99654 2.97349L1.89219 13.7387ZM8.69219 9.58218V6.3474C8.69219 5.96479 8.37915 5.65175 7.99654 5.65175C7.61393 5.65175 7.30088 5.96479 7.30088 6.3474V9.58218C7.30088 9.96479 7.61393 10.2778 7.99654 10.2778C8.37915 10.2778 8.69219 9.96479 8.69219 9.58218ZM8.60523 12.4691C8.76175 12.3126 8.8661 12.0865 8.8661 11.8604C8.8661 11.6344 8.77915 11.4083 8.60523 11.2517C8.44871 11.0952 8.22262 10.9909 7.99654 10.9909C7.77045 10.9909 7.54436 11.0778 7.38784 11.2517C7.23132 11.4083 7.12697 11.6344 7.12697 11.8604C7.12697 12.0865 7.21393 12.3126 7.38784 12.4691C7.54436 12.6257 7.77045 12.73 7.99654 12.73C8.22262 12.73 8.44871 12.6257 8.60523 12.4691Z' fill='currentColor' /></svg>"),
    ),
  );
  @each $status, $rules in $statuses {
    &[data-status="#{$status}"] {
      background: map-get($rules, background);
      border-color: map-get($rules, borderColor);
      color: map-get($rules, color);

      &::before {
        @include pseudo-icon(escape-svg(map-get($rules, icon)), 16px, 16px);
      }
    }
  }
}
