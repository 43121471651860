$background--default: #f5f5f5 !default;
$borderColor--default: #f5f5f5 !default;
$color--default: #4a4a4a !default;

$background--error: #feecf0 !default;
$borderColor--error: #feecf0 !default;
$color--error: #cc0f35 !default;

$background--info: #eff5fb !default;
$borderColor--info: #eff5fb !default;
$color--info: #296fa8 !default;

$background--success: #effaf5 !default;
$borderColor--success: #effaf5 !default;
$color--success: #257953 !default;

$background--warning: #fffaeb !default;
$borderColor--warning: #fffaeb !default;
$color--warning: #946c00 !default;

$background--active: #287d3c !default;
$borderColor--active: #287d3c !default;
$color--active: #fff !default;

$background--inactive: #da1414 !default;
$borderColor--inactive: #da1414 !default;
$color--inactive: #fff !default;

$background--primary: $Brand-color !default;
$borderColor--primary: $Brand-color !default;
$color--primary: #fff !default;

$background--primary--active: #a2224c !default;
$borderColor--primary--active: #a2224c !default;
$color--primary--active: #fff !default;

$background--primary--disabled: #e3e4ec !default;
$borderColor--primary--disabled: #e3e4ec !default;
$color--primary--disabled: #686868 !default;

$background--secondary: #fff !default;
$borderColor--secondary: $Brand-color !default;
$color--secondary: $Brand-color !default;

$background--secondary--active: #fff !default;
$borderColor--secondary--active: #a2224c !default;
$color--secondary--active: #a2224c !default;

$background--secondary--disabled: #e3e4ec !default;
$borderColor--secondary--disabled: #e3e4ec !default;
$color--secondary--disabled: #686868 !default;
