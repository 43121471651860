.Badge {
  align-items: center;
  background: #e3e4ec;
  display: inline-flex;
  font-weight: bold;
  justify-content: center;
  padding: calc($Content-gutter * 0.5) $Content-gutter;
  text-align: center;
  text-transform: uppercase;
  width: fit-content;
}
