.Grid {
  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: calc($Content-gutter * -1) 0 0 calc($Content-gutter * -1);

    &-item {
      margin: $Content-gutter 0 0 $Content-gutter;

      > div {
        height: 100%;
      }
    }

    &[data-centered="true"] & {
      justify-content: center;
    }

    &[data-column-count] & {
      &-item {
        max-width: calc(100% - $Content-gutter);
        width: 100%;
      }
    }

    @include for-desktop() {
      $columnCounts: 2, 3;
      @each $columnCount in $columnCounts {
        &[data-column-count="#{$columnCount}"] & {
          &-item {
            max-width: calc(1 / $columnCount * 100% - $Content-gutter);
          }
        }
      }
    }
  }
}
