$AutoComplete-padding: $Component-padding !default;

.AutoComplete {
  &-trigger {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: calc($AutoComplete-padding / 4 * -1) 0 0
      calc($AutoComplete-padding / 2 * -1);
    padding: calc($AutoComplete-padding / 4) calc($AutoComplete-padding / 2);

    > div {
      margin: calc($AutoComplete-padding / 4) 0 0
        calc($AutoComplete-padding / 2);
    }

    > input {
      border-color: transparent;
      flex-grow: 1;
      margin: calc($AutoComplete-padding / 4) 0 0
        calc($AutoComplete-padding / 2);
      padding: calc($AutoComplete-padding / 4) calc($AutoComplete-padding / 2);
      width: auto;
    }
  }
}
