/*
  Brand
*/

$Brand-color: #d41e33;
$Brand-fontFamily: Barlow, sans-serif;

@import "~@epc-front/shared/styles/abstracts";

/*
  Variables
*/

$fontSize--xl: 1.5rem;

$space--md: 1.5rem;

$Component-gap: 1.5rem;
$Component-padding: 1.5rem;

/*
  Body
*/

$Body-background: #f9f9f9;
$Body-color: #333333;
$Body-fontFamily: Barlow, sans-serif;
$Body-fontWeight: normal;
$Body-lineHeight: 1.25;

$Body-fontSize: 1rem;
$Small-fontSize: 0.875rem;
$Strong-fontSize: 1.125rem;

/*
  Content
*/

$Content-gutter: 1rem;
$Content-maxWidth: 90rem;

/*
  Control
*/

$Control-background: #fff;
$Control-border: 1px solid #e3e4ec;
$Control-borderRadius: 0.375rem;
$Control-boxShadow: null;
$Control-color: null;
$Control-fontFamily: null;
$Control-fontSize: null;
$Control-fontWeight: null;
$Control-lineHeight: null;
$Control--disabled-background: #f5f5f5;
$Control--disabled-border: null;
$Control--disabled-color: #686868;
$Control--readOnly-background: #f5f5f5;
$Control--readOnly-border: null;
$Control--readOnly-color: #686868;
$Control--invalid-background: null;
$Control--invalid-border: 1px solid $color--error;
$Control--invalid-color: null;
$Control-onHover-background: null;
$Control-onHover-border: 1px solid #2b2b2b;
$Control-onHover-color: null;

$Control-icon-color: #2b2b2b;

$Checkbox-size: 1rem;

$Input-placeholder-color: #686868;

$Select-optgroup-background: #f5f5f5;
$Select-option-background: #fff;

/*
  Form
*/

$Label-fontFamily: null;
$Label-fontSize: null;
$Label-fontWeight: bold;
$Label-lineHeight: null;

$Legend-background: null;
$Legend-color: null;
$Legend-fontFamily: Barlow, sans-serif;
$Legend-fontSize: 1.75rem;
$Legend-fontWeight: bold;
$Legend-lineHeight: 1.25;

/*
  Heading
*/

$Heading-background: null;
$Heading-color: null;
$Heading-fontFamily: Barlow, sans-serif;
$Heading-fontWeight: bold;
$Heading-lineHeight: 1.25;

$Heading1-fontSize: 2rem;
$Heading2-fontSize: 1.5rem;
$Heading3-fontSize: 1.25rem;
$Heading4-fontSize: 1.25rem;
$Heading5-fontSize: 1.25rem;
$Heading6-fontSize: 1.25rem;

/*
  Icon
*/

$Icon-background: $Brand-color;
$Icon-border: null;
$Icon-color: #fff;

/*
  Components
*/

$Component-background: #fff;
$Component-border: null;
$Component-borderRadius: 0.375rem;
$Component-boxShadow: 0 0.5rem 0.5rem rgba(#2b2b2b, 0.1);
$Component-color: null;

// Dialog

$Dialog-background: #fff;
$Dialog-border: null;
$Dialog-borderRadius: 0.375rem;
$Dialog-boxShadow: 0 0.5rem 0.5rem rgba(#2b2b2b, 0.1);
$Dialog-color: null;
$Dialog-zIndex: 3;

$Dialog-close-background: null;
$Dialog-close-border: null;
$Dialog-close-borderRadius: null;
$Dialog-close-boxShadow: null;
$Dialog-close-color: null;

$Dialog-title-fontFamily: null;
$Dialog-title-fontSize: 2rem;
$Dialog-title-fontWeight: null;
$Dialog-title-lineHeight: null;

// Field

$Field-description-color: $color--default;
$Field-description-fontFamily: null;
$Field-description-fontSize: 1rem;
$Field-description-fontWeight: null;
$Field-description-lineHeight: null;

$Field-errorMessage-color: $color--error;
$Field-errorMessage-fontFamily: null;
$Field-errorMessage-fontSize: 1rem;
$Field-errorMessage-fontWeight: null;
$Field-errorMessage-lineHeight: null;

$Field-indicator-color: null;
$Field-indicator-fontFamily: null;
$Field-indicator-fontSize: null;
$Field-indicator-fontWeight: null;
$Field-indicator-lineHeight: null;

$Field-label-color: null;
$Field-label-fontFamily: null;
$Field-label-fontSize: null;
$Field-label-fontWeight: bold;
$Field-label-lineHeight: null;

// Form

$Form-reminder-color: null;
$Form-reminder-fontFamily: null;
$Form-reminder-fontSize: 1rem;
$Form-reminder-fontWeight: null;
$Form-reminder-lineHeight: null;

// Nav

$Nav-background: null;
$Nav-border: null;
$Nav-borderRadius: null;
$Nav-boxShadow: null;
$Nav-color: null;

$Nav-dropdown-background: #fff;
$Nav-dropdown-border: null;
$Nav-dropdown-borderRadius: 0.375rem;
$Nav-dropdown-boxShadow: 0 0.5rem 0.5rem rgba(#2b2b2b, 0.1);
$Nav-dropdown-color: null;

$Nav-link-background: null;
$Nav-link-border: null;
$Nav-link-borderRadius: 0.375rem;
$Nav-link-boxShadow: null;
$Nav-link-color: null;
$Nav-link-fontFamily: null;
$Nav-link-fontSize: $Strong-fontSize;
$Nav-link-fontWeight: bold;
$Nav-link-lineHeight: null;
$Nav-link-padding: 0.5rem 1rem;
$Nav-link-onHover-background: #fdf4f5;
$Nav-link-onHover-border: null;
$Nav-link-onHover-color: $Brand-color;

// NavBar

$NavBar-background: #fff;
$NavBar-border: null;
$NavBar-borderRadius: null;
$NavBar-boxShadow: null;
$NavBar-color: null;

$NavBar-desktop-gutter: null;
$NavBar-mobile-gutter: null;

// Separator

$Separator-color: #e3e4ec;
$Separator-style: solid;
$Separator-width: 1px;
$Separator: $Separator-width $Separator-style $Separator-color;
