@import "~normalize.scss";

/*
  App
*/

#root {
  height: 100vh;
  min-height: 100vh;
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  height: 0 !important;
}

/*
  Body
*/

body {
  background: $Body-background;
  color: $Body-color;
  font-family: $Body-fontFamily;
  font-size: $Body-fontSize;
  font-weight: $Body-fontWeight;
  line-height: $Body-lineHeight;
}

/*
  Control
*/

a {
  color: currentColor;
  text-decoration: underline;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

button {
  background: none;
  border: none;
  color: currentColor;
  padding: 0;
  text-align: left;
  text-decoration: underline;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

input,
select,
textarea {
  appearance: none;
  background: $Control-background;
  border: $Control-border;
  border-radius: $Control-borderRadius;
  box-shadow: $Control-boxShadow;
  color: $Control-color;
  font-family: $Control-fontFamily;
  font-size: $Control-fontSize;
  font-weight: $Control-fontWeight;
  line-height: $Control-lineHeight;
  width: 100%;

  &[aria-invalid="true"] {
    background: $Control--invalid-background;
    border: $Control--invalid-border;
    color: $Control--invalid-color;
  }

  &:disabled {
    background: $Control--disabled-background;
    border: $Control--disabled-border;
    color: $Control--disabled-color;
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background: $Control-onHover-background;
      border: $Control-onHover-border;
      color: $Control-onHover-color;
    }
  }

  &:not(input[type="checkbox"]):not(select) {
    &:read-only {
      background: $Control--readOnly-background;
      border: $Control--readOnly-border;
      color: $Control--readOnly-color;
    }
  }
}

input {
  padding: calc($Content-gutter * 0.5) $Content-gutter;

  &::placeholder {
    color: $Input-placeholder-color;
  }

  &[type="checkbox"] {
    background-position: 50%;
    background-repeat: no-repeat;
    flex-shrink: 0;
    height: $Checkbox-size;
    width: $Checkbox-size;

    &:checked {
      background-image: escape-svg(
        url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' height='10' stroke='none' viewBox='0 0 11 10' width='11'><path d='M3.51105 8.75C3.36424 8.75 3.21743 8.69238 3.10559 8.57767L0.668232 6.07768C0.443923 5.84772 0.443923 5.47592 0.668232 5.24596C0.892435 5.0159 1.25484 5.0159 1.47915 5.24596L3.51105 7.32999L9.27093 1.42247C9.49514 1.19251 9.85754 1.19251 10.0818 1.42247C10.3061 1.65253 10.3061 2.02423 10.0818 2.25429L3.91651 8.57767C3.80477 8.69238 3.65786 8.75 3.51105 8.75Z' fill='#{$Control-icon-color}' stroke='#{$Control-icon-color}' /></svg>")
      );
    }
  }

  &[type="date"] {
    text-transform: uppercase;

    &::-webkit-calendar-picker-indicator,
    &::-webkit-inner-spin-button {
      background-image: escape-svg(
        url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' height='16' stroke='none' viewBox='0 0 16 16' width='16'><path d='M2.35443 8.12488H13.2089C13.6247 8.12488 13.9618 7.78781 13.9618 7.37201C13.9618 6.95621 13.6247 6.61914 13.2089 6.61914H2.35443C1.93863 6.61914 1.60156 6.95621 1.60156 7.37201C1.60156 7.78781 1.93863 8.12488 2.35443 8.12488Z' fill='#{$Control-icon-color}' /><path d='M6.37293 5.11341V2.35248C6.37293 1.93668 6.03586 1.59961 5.62006 1.59961C5.20426 1.59961 4.86719 1.93668 4.86719 2.35248V5.11341C4.86719 5.52921 5.20426 5.86628 5.62006 5.86628C6.03586 5.86628 6.37293 5.52921 6.37293 5.11341Z' fill='#{$Control-icon-color}' /><path d='M11.1386 5.11341V2.35248C11.1386 1.93668 10.8015 1.59961 10.3857 1.59961C9.96988 1.59961 9.63281 1.93668 9.63281 2.35248V5.11341C9.63281 5.52921 9.96988 5.86628 10.3857 5.86628C10.8015 5.86628 11.1386 5.52921 11.1386 5.11341Z' fill='#{$Control-icon-color}' /><path fill-rule='evenodd' clip-rule='evenodd' d='M3.17067 14.3997H12.9656C13.8325 14.3997 14.5347 13.6975 14.5347 12.8312V4.67405C14.5347 3.8077 13.8325 3.10547 12.9656 3.10547H3.17067C2.30377 3.10547 1.60156 3.8077 1.60156 4.67405V12.8312C1.60156 13.6975 2.30377 14.3997 3.17067 14.3997ZM13.029 12.8311C13.029 12.8659 13.0009 12.894 12.9656 12.894H3.17067C3.13538 12.894 3.1073 12.8659 3.1073 12.8311V4.67403C3.1073 4.63926 3.13538 4.61119 3.17067 4.61119H12.9656C13.0009 4.61119 13.029 4.63926 13.029 4.67403V12.8311Z' fill='#{$Control-icon-color}' /></svg>")
      );
    }
  }
}

select {
  background-image: escape-svg(
    url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' height='14' stroke='none' viewBox='0 0 6 14' width='6'><path clip-rule='evenodd' d='M3.28228 13.8453L5.88234 10.4056C6.13377 10.0729 5.9557 9.50418 5.60013 9.50418L0.399993 9.50418C0.0444231 9.50418 -0.133647 10.0729 0.117779 10.4056L2.71785 13.8453C2.87371 14.0515 3.12641 14.0515 3.28228 13.8453ZM2.71781 0.154651L0.117738 3.59445C-0.133687 3.92708 0.0443826 4.49582 0.399952 4.49582L5.60009 4.49582C5.95566 4.49582 6.13373 3.92708 5.8823 3.59445L3.28223 0.154651C3.12637 -0.0515502 2.87367 -0.0515502 2.71781 0.154651Z' fill='#{$Control-icon-color}' fill-rule='evenodd' /></svg>")
  );
  background-position: calc(100% - $Content-gutter) 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  padding: calc($Content-gutter * 0.5) calc($Content-gutter * 2)
    calc($Content-gutter * 0.5) $Content-gutter;
  text-overflow: ellipsis;
  white-space: nowrap;

  optgroup {
    background: $Select-optgroup-background;
    text-transform: uppercase;
  }

  option {
    background: $Select-option-background;
  }
}

textarea {
  padding: calc($Content-gutter * 0.5) $Content-gutter;
  resize: vertical;
}

/*
  Embed
*/

figure {
  margin: 0;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }
}

iframe {
  border: none;
}

img,
svg {
  flex-shrink: 0;
  vertical-align: middle;
}

/*
  Form
*/

fieldset {
  border: none;
  margin: 0;
  padding: 0;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }
}

form {
  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }
}

legend {
  background: $Legend-background;
  color: $Legend-color;
  font-family: $Legend-fontFamily;
  font-weight: $Legend-fontWeight;
  line-height: $Legend-lineHeight;
  margin: 0 0 $Content-gutter 0;
  padding: 0;
  white-space: pre-wrap;
  @include font-size($Legend-fontSize);
}

/*
  Heading
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  background: $Heading-background;
  color: $Heading-color;
  font-family: $Heading-fontFamily;
  font-weight: $Heading-fontWeight;
  line-height: $Heading-lineHeight;
  margin: 0 0 $Content-gutter 0;
  padding: 0;
  white-space: pre-wrap;

  > strong {
    color: $Brand-color;
  }
}

h1 {
  @include font-size($Heading1-fontSize);
}

h2 {
  @include font-size($Heading2-fontSize);
}

h3 {
  @include font-size($Heading3-fontSize);
}

h4 {
  @include font-size($Heading4-fontSize);
}

h5 {
  @include font-size($Heading5-fontSize);
}

h6 {
  @include font-size($Heading6-fontSize);
}

/*
  List
*/

dd {
  font-weight: bold;
  margin: 0;
}

dl {
  margin: 0;
}

ol,
ul {
  margin: 0 0 $Content-gutter 0;
}

/*
  Nav
*/

nav {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

/*
  Text
*/

p {
  margin: 0;
  padding: 0;
  white-space: pre-line;

  &:not(:last-child) {
    margin-bottom: $Content-gutter;
  }
}

strong {
  > span {
    color: $Brand-color;
  }
}
