@import "@epc-front/shared/components/Badge";

.Badge {
  border-radius: 0.375rem;

  &[data-context="DocumentCategory"] {
    width: 13rem;

    $values: (
      ACCOUNTABLE: (
        background: #fb7d7d,
        color: #fff,
      ),
      BUILDING_MAINTENANCE: (
        background: #7d7dfb,
        color: #fff,
      ),
      CONTRACT: (
        background: #7dddfb,
        color: #fff,
      ),
      LEGAL_BUILDING: (
        background: #da7dfb,
        color: #fff,
      ),
      LEGAL_TENANT: (
        background: #fbaa7d,
        color: #fff,
      ),
      LITIGATION: (
        background: #fbaa7d,
        color: #fff,
      ),
    );
    @each $value, $rules in $values {
      &[data-value="#{$value}"] {
        background: map-get($rules, background);
        color: map-get($rules, color);
      }
    }
  }

  &[data-context="InterlocutorRole"] {
    width: 9rem;

    $values: (
      ACCOUNTANT: (
        background: #fb7d7d,
        color: #fff,
      ),
      ADMINISTRATOR: (
        background: #fff,
        color: #2b2b2b,
      ),
      TECHNICIAN: (
        background: #fbb97d,
        color: #fff,
      ),
    );
    @each $value, $rules in $values {
      &[data-value="#{$value}"] {
        background: map-get($rules, background);
        color: map-get($rules, color);
      }
    }
  }

  &[data-context="LotCategory"] {
    width: 12rem;

    $values: (
      ANNEX: (
        background: #fb7d7d,
        color: #fff,
      ),
      ANTENNA: (
        background: #7d7dfb,
        color: #fff,
      ),
      ANTENNA_RELAY: (
        background: #7d7dfb,
        color: #fff,
      ),
      APARTMENT: (
        background: #7dddfb,
        color: #fff,
      ),
      APARTMENT_DUPLEX: (
        background: #7dddfb,
        color: #fff,
      ),
      APARTMENT_TRIPLEX: (
        background: #7dddfb,
        color: #fff,
      ),
      ARCHIVE: (
        background: #fb7d7d,
        color: #fff,
      ),
      ATTIC: (
        background: #fb7d7d,
        color: #fff,
      ),
      BARE_LAND: (
        background: #229926,
        color: #fff,
      ),
      BEDROOM: (
        background: #7dddfb,
        color: #fff,
      ),
      BIKE_ROOM: (
        background: #fbaa7d,
        color: #fff,
      ),
      BILLBOARD: (
        background: #7d7dfb,
        color: #fff,
      ),
      BUILDING: (
        background: #7dddfb,
        color: #fff,
      ),
      BUSINESS_TRANSFER: (
        background: #da7dfb,
        color: #fff,
      ),
      CASTLE: (
        background: #7dddfb,
        color: #fff,
      ),
      CAVE: (
        background: #fb7d7d,
        color: #fff,
      ),
      CELLAR: (
        background: #fb7d7d,
        color: #fff,
      ),
      CHALET: (
        background: #7dddfb,
        color: #fff,
      ),
      CLINIC: (
        background: #da7dfb,
        color: #fff,
      ),
      CO_OWNERSHIP_SYNDICATE: (
        background: #da7dfb,
        color: #fff,
      ),
      COMMERCIAL: (
        background: #da7dfb,
        color: #fff,
      ),
      COMMERCIAL_AREAS: (
        background: #da7dfb,
        color: #fff,
      ),
      COMMERCIAL_SPACE: (
        background: #da7dfb,
        color: #fff,
      ),
      CORRIDOR: (
        background: #fbaa7d,
        color: #fff,
      ),
      GUARD_LODGE: (
        background: #7dddfb,
        color: #fff,
      ),
      GARDEN: (
        background: #229926,
        color: #fff,
      ),
      HORSE_RIDING: (
        background: #da7dfb,
        color: #fff,
      ),
      HOTEL: (
        background: #da7dfb,
        color: #fff,
      ),
      HOUSE: (
        background: #7dddfb,
        color: #fff,
      ),
      INDOOR_PARKING: (
        background: #fbaa7d,
        color: #fff,
      ),
      LOCAL_INDUSTRY: (
        background: #da7dfb,
        color: #fff,
      ),
      LOG: (
        background: #7dddfb,
        color: #fff,
      ),
      MANSION: (
        background: #7dddfb,
        color: #fff,
      ),
      OFFICE: (
        background: #da7dfb,
        color: #fff,
      ),
      OUTDOOR_PARKING: (
        background: #fbaa7d,
        color: #fff,
      ),
      PARCEL: (
        background: #229926,
        color: #fff,
      ),
      PAVILION: (
        background: #7dddfb,
        color: #fff,
      ),
      PRIVATE_USE: (
        background: #7dddfb,
        color: #fff,
      ),
      PROFESSIONAL_ROOM: (
        background: #da7dfb,
        color: #fff,
      ),
      SERVICE_ROOM: (
        background: #da7dfb,
        color: #fff,
      ),
      SERVICE_SPACE: (
        background: #da7dfb,
        color: #fff,
      ),
      SHARED_COMPANY_CAFETERIA: (
        background: #da7dfb,
        color: #fff,
      ),
      SHARED_ROOM: (
        background: #da7dfb,
        color: #fff,
      ),
      SHELTER: (
        background: #da7dfb,
        color: #fff,
      ),
      SIGN_DISPLAY: (
        background: #7d7dfb,
        color: #fff,
      ),
      SKI_CLOSET: (
        background: #fb7d7d,
        color: #fff,
      ),
      STORAGE: (
        background: #fb7d7d,
        color: #fff,
      ),
      STORAGE_BUILDING: (
        background: #fb7d7d,
        color: #fff,
      ),
      STORE_ROOM: (
        background: #fb7d7d,
        color: #fff,
      ),
      STUDIO: (
        background: #7dddfb,
        color: #fff,
      ),
      TERRACE: (
        background: #229926,
        color: #fff,
      ),
      TOILET: (
        background: #7dddfb,
        color: #fff,
      ),
      TOWNHOUSE: (
        background: #7dddfb,
        color: #fff,
      ),
      UNIDENTIFIED_PRINCIPAL: (
        background: #979797,
        color: #fff,
      ),
      UNKNOWN_TYPE: (
        background: #979797,
        color: #fff,
      ),
      VARIOUS: (
        background: #2b2b2b,
        color: #fff,
      ),
      WINDOW: (
        background: #da7dfb,
        color: #fff,
      ),
      WORKSHOP: (
        background: #da7dfb,
        color: #fff,
      ),
      YARD: (
        background: #229926,
        color: #fff,
      ),
    );
    @each $value, $rules in $values {
      &[data-value="#{$value}"] {
        background: map-get($rules, background);
        color: map-get($rules, color);
      }
    }
  }

  &[data-context="MandateCategory"] {
    width: 12rem;

    $values: (
      MANAGEMENT_MANDATE: (
        background: #7d7dfb,
        color: #fff,
      ),
      TRUSTEE_MANDATE: (
        background: #fb7d7d,
        color: #fff,
      ),
    );
    @each $value, $rules in $values {
      &[data-value="#{$value}"] {
        background: map-get($rules, background);
        color: map-get($rules, color);
      }
    }
  }
}
