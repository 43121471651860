.for-desktop {
  @media (max-width: 960px) {
    display: none;
  }
}

.for-mobile {
  @media (min-width: 960px) {
    display: none;
  }
}

.visually-hidden {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  white-space: nowrap !important;
  width: 1px !important;
}
