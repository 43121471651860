.ButtonContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: $Content-gutter;
  justify-content: center;
  text-align: center;

  &[data-variant="download"] & {
    &-label {
      align-items: center;
      display: flex;

      &::before {
        @include pseudo-icon(
          escape-svg(
            url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 21 28'><path d='M13 1L13.5303 0.46967C13.3158 0.255171 12.9932 0.191005 12.713 0.30709C12.4327 0.423176 12.25 0.696653 12.25 1L13 1ZM13 8H12.25C12.25 8.41421 12.5858 8.75 13 8.75V8ZM20 8V8.75C20.3033 8.75 20.5768 8.56727 20.6929 8.28701C20.809 8.00676 20.7448 7.68417 20.5303 7.46967L20 8ZM13.1428 7.70862H12.3928C12.3928 8.12283 12.7286 8.45862 13.1428 8.45862V7.70862ZM13.1428 1.0001H13.8928C13.8928 0.585886 13.557 0.2501 13.1428 0.2501V1.0001ZM20 7.70862H20.75C20.75 7.29441 20.4142 6.95862 20 6.95862V7.70862ZM3.6084 9.67227C3.19418 9.67227 2.8584 10.0081 2.8584 10.4223C2.8584 10.8365 3.19418 11.1723 3.6084 11.1723V9.67227ZM7.78901 11.1723C8.20323 11.1723 8.53901 10.8365 8.53901 10.4223C8.53901 10.0081 8.20323 9.67227 7.78901 9.67227V11.1723ZM4 16.75C3.58579 16.75 3.25 17.0858 3.25 17.5C3.25 17.9142 3.58579 18.25 4 18.25V16.75ZM16 18.25C16.4142 18.25 16.75 17.9142 16.75 17.5C16.75 17.0858 16.4142 16.75 16 16.75V18.25ZM12.25 1V8H13.75V1H12.25ZM13 8.75H20V7.25H13V8.75ZM13.8928 7.70862V1.0001H12.3928V7.70862H13.8928ZM13.1428 0.2501H2V1.7501H13.1428V0.2501ZM0.25 2.0001V26.0001H1.75V2.0001H0.25ZM2 27.7501H19V26.2501H2V27.7501ZM20.75 26.0001V7.70862H19.25V26.0001H20.75ZM20 6.95862H13.1428V8.45862H20V6.95862ZM19 27.7501C19.9665 27.7501 20.75 26.9666 20.75 26.0001H19.25C19.25 26.1382 19.1381 26.2501 19 26.2501V27.7501ZM0.25 26.0001C0.25 26.9666 1.0335 27.7501 2 27.7501V26.2501C1.86193 26.2501 1.75 26.1382 1.75 26.0001H0.25ZM2 0.2501C1.0335 0.2501 0.25 1.0336 0.25 2.0001H1.75C1.75 1.86203 1.86193 1.7501 2 1.7501V0.2501ZM20.5303 7.46967L13.5303 0.46967L12.4697 1.53033L19.4697 8.53033L20.5303 7.46967ZM3.6084 11.1723H7.78901V9.67227H3.6084V11.1723ZM4 18.25H16V16.75H4V18.25Z' fill='currentColor' /></svg>")
          ),
          24px,
          24px
        );
        margin-right: calc($Content-gutter * 0.5);
      }
    }
  }
}
