@mixin for-desktop {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin pseudo-icon($image, $width, $height) {
  background: currentColor;
  content: "";
  display: flex;
  flex-shrink: 0;
  height: $height;
  mask-image: $image;
  mask-repeat: no-repeat;
  width: $width;
}
