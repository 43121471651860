.NavBar {
  align-items: center;
  background: $NavBar-background;
  border: $NavBar-border;
  border-radius: $NavBar-borderRadius;
  box-shadow: $NavBar-boxShadow;
  color: $NavBar-color;
  display: flex;
  padding: $Content-gutter;
  position: sticky;
  top: 0;
  z-index: 3;

  &-desktop {
    align-items: center;
    display: none;
    width: 100%;

    &[data-orientation="vertical"] {
      flex-direction: column;
    }

    @include for-desktop() {
      display: flex;
    }

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }
  }

  &-dialog {
    background: $NavBar-background;
    border: $NavBar-border;
    border-radius: $NavBar-borderRadius;
    box-shadow: $NavBar-boxShadow;
    color: $NavBar-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;

    &:focus {
      outline: none;
    }

    @media (prefers-reduced-motion: no-preference) {
      &[data-state="closed"] {
        animation: NavBarDialogClosed 125ms ease-in;

        @keyframes NavBarDialogClosed {
          from {
            left: 0;
          }

          to {
            left: -100vh;
          }
        }
      }

      &[data-state="open"] {
        animation: NavBarDialogOpen 125ms ease-out;

        @keyframes NavBarDialogOpen {
          from {
            left: -100vh;
          }

          to {
            left: 0;
          }
        }
      }
    }

    &-close {
      font-size: 1.5rem;
      left: $Content-gutter;
      position: absolute;
      top: $Content-gutter;
      z-index: 1;
    }

    &-logo {
      align-items: center;
      background: $NavBar-background;
      border: $NavBar-border;
      border-radius: $NavBar-borderRadius;
      box-shadow: $NavBar-boxShadow;
      color: $NavBar-color;
      display: flex;
      justify-content: center;
      padding: $Content-gutter;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    &-scrollArea {
      overflow: auto;
      padding: 0 $Content-gutter;

      > :not(:last-child) {
        margin-bottom: $Content-gutter;
      }
    }

    &-trigger {
      left: $Content-gutter;
      opacity: 0.75;
      position: absolute;
      top: $Content-gutter;
      z-index: 1;

      &::before {
        @include pseudo-icon(
          escape-svg(
            url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 40 40'><path d='M5.64103 17.2821H34.359C35.2653 17.2821 36 16.5473 36 15.641C36 14.7347 35.2653 14 34.359 14L5.64103 14C4.73471 14 4 14.7347 4 15.641C4 16.5473 4.73471 17.2821 5.64103 17.2821Z' fill='currentColor' /><path d='M5.64103 26.8406H23.8254C24.7317 26.8406 25.4664 26.1059 25.4664 25.1996C25.4664 24.2933 24.7317 23.5586 23.8254 23.5586H5.64103C4.73471 23.5586 4 24.2933 4 25.1996C4 26.1059 4.73471 26.8406 5.64103 26.8406Z' fill='currentColor' /></svg>")
          ),
          40px,
          40px
        );
      }

      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }

  &-logo {
    margin: 0 auto;

    .NavBar-desktop & {
      padding: $NavBar-desktop-gutter;
    }

    .NavBar-mobile & {
      padding: $NavBar-mobile-gutter;
    }
  }

  &-mobile {
    display: flex;
    width: 100%;

    @include for-desktop() {
      display: none;
    }
  }
}
