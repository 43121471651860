@import "~slick-carousel/slick/slick.scss";

.Carousel {
  display: flex;
  flex-direction: column;
  height: 100%;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-content {
    display: flex;

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }
  }

  &-control {
    align-items: center;
    background: $background--primary;
    border: 1px solid $borderColor--primary;
    border-radius: 1.625rem;
    color: $color--primary;
    display: inline-flex !important;
    height: 3.25rem;
    justify-content: center;
    margin-top: -1.625rem;
    position: absolute;
    top: 50%;
    width: 3.25rem;
    z-index: 1;

    &:focus,
    &:hover {
      background: $background--primary--active;
      border: 1px solid $borderColor--primary--active;
      color: $color--primary--active;
    }

    &:first-child {
      left: 0;

      &::before {
        @include pseudo-icon(
          escape-svg(
            url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 31 15'><path d='M29.5645 8.86418H5.72239C4.92958 8.86418 4.28688 8.22148 4.28688 7.42867C4.28688 6.63586 4.92958 5.99316 5.72239 5.99316H29.5645C30.3573 5.99316 31 6.63586 31 7.42867C31 8.22148 30.3573 8.86418 29.5645 8.86418Z' fill='currentColor' /><path clip-rule='evenodd' d='M0.137377 7.05161L8.35458 0.60106C8.59008 0.416196 8.93506 0.583963 8.93506 0.883348V13.7845C8.93506 14.0838 8.59008 14.2516 8.35458 14.0667L0.137377 7.61619C-0.0456619 7.47251 -0.0456619 7.1953 0.137377 7.05161ZM0.940196 7.33393L8.21733 13.0465V1.62134L0.940196 7.33393Z' fill='currentColor' fill-rule='evenodd' /><path clip-rule='evenodd' d='M8.41981 0.883308L0.202602 7.33386L8.41981 13.7844V0.883308Z' fill='currentColor' fill-rule='evenodd' /></svg>")
          ),
          27px,
          13px
        );
      }
    }

    &:last-child {
      right: 0;

      &::before {
        @include pseudo-icon(
          escape-svg(
            url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 27 13'><path d='M1.37106 7.59759H21.8071C22.4867 7.59759 23.0376 7.0467 23.0376 6.36715C23.0376 5.6876 22.4867 5.13672 21.8071 5.13672H1.37106C0.69151 5.13672 0.140625 5.6876 0.140625 6.36715C0.140625 7.0467 0.69151 7.59759 1.37106 7.59759Z' fill='currentColor' /><path clip-rule='evenodd' d='M26.5956 6.04448L19.5522 0.515438C19.3504 0.356984 19.0547 0.500784 19.0547 0.7574V11.8155C19.0547 12.0721 19.3504 12.2159 19.5522 12.0575L26.5956 6.52841C26.7524 6.40525 26.7524 6.16764 26.5956 6.04448ZM25.9074 6.28647L19.6699 11.183V1.38996L25.9074 6.28647Z' fill='currentColor' fill-rule='evenodd' /><path clip-rule='evenodd' d='M19.4978 0.757086L26.5411 6.28613L19.4978 11.8152V0.757086Z' fill='currentColor' fill-rule='evenodd' /></svg>")
          ),
          27px,
          13px
        );
      }
    }
  }

  &-indicator {
    font-size: $Strong-fontSize;
    font-weight: bold;
    text-align: center;
  }

  &-slide {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    padding: 0 calc($Content-gutter * 0.5);

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }

    &-action {
      margin: calc($Content-gutter * 0.5) 0 0 calc($Content-gutter * 0.5);
    }

    &-content {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin: calc($Content-gutter * -0.5) 0 0 calc($Content-gutter * -0.5);
    }

    &-image {
      flex-grow: 1;
      position: relative;

      > img {
        display: block;
        height: 100%;
        object-fit: contain;
        position: absolute;
        width: 100%;
      }
    }

    &-title {
      flex-grow: 1;
      margin: calc($Content-gutter * 0.5) 0 0 calc($Content-gutter * 0.5);
    }
  }

  &-slider {
    flex-grow: 1;
    height: 100%;

    > div,
    > div > div,
    > div > div > div,
    > div > div > div > div {
      height: 100%;
    }

    > div {
      position: relative;

      &::after,
      &::before {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 3rem;
        z-index: 1;
      }

      &::after {
        background: linear-gradient(-90deg, #fff 15%, transparent 85%);
        right: 0;
      }

      &::before {
        background: linear-gradient(90deg, #fff 15%, transparent 85%);
        left: 0;
      }
    }
  }
}
