.PreviewCard {
  background: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 0.5rem rgba(#979797, 0.25);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;

  @include for-desktop() {
    align-items: center;
    flex-direction: row;
  }

  > div {
    padding: 0.5rem;
  }

  &-append {
    flex-basis: auto;
    flex-grow: 1;

    @include for-desktop() {
      flex-grow: 0;
      margin-left: auto;
    }

    a,
    button {
      width: 100%;

      @include for-desktop() {
        width: auto;
      }
    }

    > span {
      display: inline-flex;
      margin-right: 0.5rem;
      padding: 0.5rem;
    }
  }

  &-item {
    flex-grow: 1;

    > span[role="img"] {
      margin-right: 0.5rem;
    }

    > svg {
      height: 1.25rem;
      margin-right: 0.5rem;
      width: 1.25rem;
    }

    > ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        &:not(:last-child) {
          &::after {
            content: "|";
            margin: 0 0.5rem;
          }
        }
      }
    }
  }

  &-prepend {
    flex-basis: auto;
    flex-grow: 0;
    width: auto;

    > svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}
