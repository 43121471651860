@import "../../styles/abstracts";

$MainPage-variants: (
  currentUser: (
    background: linear-gradient(90deg, #d41e33 75%, #e44154 150%),
  ),
  documents: (
    background: linear-gradient(90deg, #48accf 75%, #70beda 150%),
  ),
  interlocutors: (
    background: linear-gradient(90deg, #ffba4c 75%, #ffce7f 150%),
  ),
  patrimony: (
    background: linear-gradient(90deg, #28b775 75%, #3dd58f 150%),
  ),
  reportings: (
    background: linear-gradient(90deg, #48accf 75%, #70beda 150%),
  ),
);

.MainPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto 1rem auto;
    max-width: 90rem;
    padding: 0 1rem;
    position: relative;
    width: 100%;

    > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &-catchPhrase {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
  }

  &-details {
    color: #fff;
    min-height: 1.75rem;

    > div {
      background: none;
      box-shadow: none;

      > div {
        padding: 0;

        > div {
          > ul {
            > li {
              border-color: #fff;
            }
          }
        }

        @include for-desktop() {
          align-items: center;
          flex-direction: row;
          margin-bottom: -1rem;

          > h2 {
            margin: 0 1rem 0 0;
          }

          > div {
            > ul {
              column-gap: 1rem;
              display: flex;
              flex-direction: row;
              margin: 0;

              > li {
                border: none;
                margin: 0;
                padding: 0;

                &:last-child {
                  margin-bottom: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }

  &-header {
    margin-bottom: 1rem;

    > div {
      margin: 0 auto;
      max-width: 90rem;
      padding: 1rem;
      width: 100%;
    }
  }

  &-title {
    align-items: center;
    border-bottom: 1px solid #fff;
    color: #fff;
    display: flex;
    padding-bottom: 1rem;

    > svg {
      height: 2rem;
      margin-right: 0.5rem;
      width: 2rem;
    }
  }

  @each $MainPage-variant, $rules in $MainPage-variants {
    &--#{$MainPage-variant} {
      .MainPage {
        &-header {
          background: map-get($rules, background);
        }
      }
    }
  }
}
